<template>
  <svg
    class="svg-icon"
    :class="bem('svg-icon', { icon, color })"
    :style="{ '--svg-icon--size': size }"
  >
    <use :xlink:href="`${source}#${icon}`" />
  </svg>
</template>

<script>
import { bem } from 'src/utils/bem.js';

export default {
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      default: '/images/icons-28.svg',
    },
    color: String,
    size: [String, Number],
  },
  methods: {
    bem,
  },
};
</script>
